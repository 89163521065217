<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
      <QueryModule :query-module="queryModule" @queryRes="queryRes" @reset="reset">
        <el-col :span="8">
          <el-date-picker v-model="queryModule.timeCond" end-placeholder="结束日期" range-separator="至" start-placeholder="开始日期" type="daterange" />
        </el-col>
        <el-col :span="8">
          <el-form-item label="项目名称">
            <el-input v-model="queryModule.projectName" />
          </el-form-item>
        </el-col>
      </QueryModule>

      <el-button class="margin-10-0" size="small" type="primary" @click="addDialog()"> 新增 </el-button>
      <el-table v-loading="loading" :data="tableData" fit stripe>
        <el-table-column :index="(queryModule.currentPage - 1) * 5 + 1" label="序号" type="index" />
        <el-table-column label="项目ID" prop="projectId" />
        <el-table-column label="项目名称">
          <template #default="scope">
            <el-button size="mini" type="text" @click="toRrojectDetails(scope.row)">
              {{ scope.row.projectName }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="标签" prop="nickname" />
        <el-table-column label="创建人" prop="createBy" />
        <el-table-column label="创建时间" prop="createTime" width="140" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-tooltip class="item" content="修改" effect="light" placement="top-end">
              <el-button circle size="mini" type="primary" @click="addDialog(scope.row)">
                <el-icon>
                  <i-edit />
                </el-icon>
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" content="删除" effect="light" placement="top-end">
              <el-button circle size="mini" type="danger" @click="delProject(scope.row)">
                <el-icon>
                  <i-delete-filled />
                </el-icon>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-model:currentPage="queryModule.currentPage"
        :page-size="pageSize"
        :total="queryModule.totalElements"
        background
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <el-dialog v-model="dialog.isShow" :title="dialog.title">
    <el-form ref="myProject" :model="myProject" :rules="projectRules">
      <el-form-item v-if="dialog.isAdd" label="项目ID" prop="projectId">
        <el-input v-model="myProject.projectId" :disabled="true" autocomplete="off" placeholder="请输入项目ID" />
      </el-form-item>
      <el-form-item label="项目名称" prop="projectName">
        <el-input v-model="myProject.projectName" autocomplete="off" placeholder="请输入项目名称" />
      </el-form-item>
      <el-form-item label="项目描述" prop="projectDesc">
        <el-input v-model="myProject.projectDesc" placeholder="请输入项目描述" type="textarea" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="addProject('myProject')">确 定</el-button>
        <el-button @click="dialog.isShow = false">取 消</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑
import QueryModule from '/src/components/QueryModule' //查询
import { mapGetters } from 'vuex'

export default {
  name: 'SystemProject',
  //注册组件
  components: {
    BreadCrumb,
    QueryModule,
  },
  data() {
    return {
      loading: true,
      // Dialog 对话框
      dialog: {
        isShow: false,
        isAdd: false,
        title: '新增',
      },
      // table数据
      tableData: '', //当前页的 数据
      //翻页
      queryModule: {
        //查询
        timeCond: null,
        createBy: '',
        projectName: '',
        totalElements: 0,
        currentPage: 1,
      },
      myProject: {
        //新增
        projectId: '',
        projectName: '',
        projectDesc: '',
      },
      pageP1: '',
      // UsersRules 表单验证，需要在 el-form-item 元素中增加 prop 属性
      projectRules: {
        //新增用户表单验证
        projectId: [
          {
            required: true,
            message: '项目ID不可为空',
            trigger: 'blur',
          },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        projectName: [
          {
            required: true,
            message: '项目不可为空',
            trigger: 'blur',
          },
        ],
        projectDesc: [
          {
            required: true,
            message: '项目描述不可为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
    this.queryModule.createBy = this.user.username
    if (this.$route.query.pageP1) {
      //说明是 返回上一页 进入的
      this.queryModule.currentPage = this.$route.query.pageP1 - 0
    }
    this.projectList()
  },
  computed: {
    ...mapGetters(['pageSize', 'user']),
  },
  methods: {
    //清空对象
    reset() {
      this.queryModule.projectName = ''
      this.queryModule['timeCond'] = null
      this.queryModule.currentPage = 1
      this.projectList()
    },
    //查询
    queryRes() {
      this.projectList()
    },
    //项目列表
    projectList() {
      const that = this
      this.$axios
        .POST('/api/v1/core/project/all', {
          // createBy: that.queryModule.createBy,
          page: that.queryModule.currentPage - 1,
          projectName: that.queryModule.projectName,
          size: that.pageSize,
          timeCond: that.queryModule.timeCond,
        })
        .then(function (res) {
          that.tableData = res.data.content
          that.queryModule.totalElements = res.data.totalElements
          that.clearLoadingStatus()
        })
    },
    //新增项目模态框
    addDialog(row) {
      if (row == undefined) {
        this.dialog.title = '新增'
        this.dialog.isAdd = false
        Object.keys(this.myProject).forEach(key => (this.myProject[key] = ''))
      } else {
        this.dialog.title = '修改'
        this.dialog.isAdd = true
        this.myProject.projectId = row.projectId
        this.myProject.projectName = row.projectName
        this.myProject.projectDesc = row.projectDesc
      }
      this.dialog.isShow = true
    },
    //新增 项目
    addProject(myProject) {
      var that = this
      let { projectId, projectName, projectDesc } = that.myProject
      that.$refs[myProject].validate(valid => {
        if (valid) {
          let datas = {
            projectId: projectId,
            projectName: projectName,
            projectDesc: projectDesc,
          }
          this.$axios.PUT('/api/v1/core/project', datas).then(function () {
            that.dialog.isShow = false
            that.$message({
              showClose: true,
              message: '项目' + that.dialog.title + '成功',
              type: 'success',
            })
            that.projectList()
          })
        } else {
          return false
        }
      })
    },
    //删除用户
    delProject(row) {
      var _this = this
      console.log('删除项目')
      console.log(row)
      this.$confirm('此操作将永久删除该数据，是否继续操作?', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      })
        .then(() => {
          this.$axios.DELETE('/api/v1/core/project/' + row.projectId).then(function () {
            _this.$message({
              showClose: true,
              type: 'success',
              message: '删除成功',
            })
            if (_this.queryModule.currentPage > 1) {
              if (_this.tableData.length == 1) {
                //此页面只有一天数据，删除后显示上一页
                _this.queryModule.currentPage--
              }
            }
            _this.projectList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleCurrentChange() {
      this.projectList()
    },
    toRrojectDetails(row) {
      this.pageP1 = this.queryModule.currentPage
      this.$router.push({
        name: 'projectDetails',
        query: {
          projectId: row.projectId,
          pageP1: this.pageP1,
        },
      })
    },
    clearLoadingStatus() {
      this.loading = false
    },
  },
}
</script>

<style></style>
